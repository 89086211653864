<template>
  <van-nav-bar
    title="成品油溯源码"
    fixed
    placeholder
    v-if="slideVerifyTrue && isWechatCore"
  />
  <div v-if="!isWechatCore" style="text-align: center;line-height: 80px;">
    无数据，请使用微信扫描
  </div>
  <!-- 滑块验证 -->

  <div class="slider" v-if="isWechatCore && !slideVerifyTrue">
    <div class="cover">
      <van-image width="70%" :src="require('./assets/sliderverify.png')" />
      <div>为保证您的信息安全，请进行验证</div>
    </div>
    <slide-verify
      style="display: block;"
      @onSuccess="(val) => (slideVerifyTrue = val)"
      handlerIcon="arrow"
      successIcon="passed"
    ></slide-verify>
  </div>
  <!-- 内容 -->
  <van-tabs
    v-if="slideVerifyTrue && isWechatCore"
    v-model:active="active"
    sticky
    animated
    swipeable
    :offset-top="isWechatCore ? 0 : 40"
    @change="onChangeTab"
  >
    <van-tab title="油品追溯">
      <Trace />
    </van-tab>
    <van-tab title="油机检定">
      <CheckDetail />
    </van-tab>
    <van-tab title="投诉意见">
      <Comments />
    </van-tab>
  </van-tabs>
  <flat-button @select="onSelect" v-if="slideVerifyTrue && isWechatCore" />
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isWechat } from './utils/utils';
// Pages
import Trace from './Views/Trace.vue';
import CheckDetail from './Views/CheckDetail.vue';
import Comments from './Views/Comments.vue';
import SlideVerify from '@/components/SliderVerify.vue'; //引入
// Components
import FlatButton from './components/FlatButton.vue';
import { decrypt } from '@/utils/utils';
const slideVerifyTrue = ref(false);

const router = useRouter();
const route = useRoute();

const isWechatCore = ref(isWechat());
const active = ref(route.query.tabIndex * 1 || 0);

const { p, site_code } = route.query;
const storeData = (params) => {
  localStorage.setItem('siteCode', params.site_code);
  localStorage.setItem('tankerCode', params.tanker_code);
  localStorage.setItem('loginType', params.login_type);
  localStorage.setItem('encryptP', p);
  localStorage.setItem('encryptSiteCode', site_code);
  // localStorage.setItem('loginType', params.login_type);
};

const routerPush = ref({});
const handleDecrypt = () => {
  const _site_code = decrypt(site_code);
  const _p = decrypt(p);
  const arr = _p.split('&');
  routerPush.value = {
    site_code: _site_code,
    tanker_code: arr[0].split('=')[1],
    // site_id: arr[1].split('=')[1],
    login_type: arr[1].split('=')[1],
  };
  // 存储信息
  storeData(routerPush.value);
};
handleDecrypt();

const onChangeTab = (e) => {
  router.push({
    path: '/',
    query: {
      p,
      site_code,
      tabIndex: e,
      t: Math.floor(Math.random() * 9999999999),
    },
  });
};

const onSelect = ({ index }) => {
  if (index === 0) {
    router.push({
      path: '/search',
      query: {
        ...route.query,
      },
    });
  } else {
    if (index === 1) {
      router.push({
        path: '/',
        query: {
          ...route.query,
          tabIndex: 1,
        },
      });
    } else if (index === 2) {
      router.push({
        path: '/',
        query: {
          ...route.query,
          tabIndex: 2,
        },
      });
    }
    active.value = index;
  }
};
</script>
<style lang="less">
.van-tabs__line {
  background: linear-gradient(to right, #ff3329, #ff7d43);
  box-shadow: 0px 4px 9px 1px rgba(255, 103, 96, 0.7);
  width: 50px;
  height: 4px;
  border-radius: 0;
}

.cover {
  text-align: center;
  color: #618dee;
  margin-bottom: 50px;
  line-height: 60px;
}
.slider {
  width: fit-content;
  // background-color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
</style>
