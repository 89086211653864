<template>
  <div class="sticky">
    <van-popover
      v-model:show="showPopover"
      :actions="actions"
      placement="left-end"
      overlay
      @select="onSelect"
      @open="onOpen"
      @close="onOpen"
    >
      <template #reference>
        <!-- <van-button icon="apps-o" round class="flat-btn" /> -->
        <van-image
          :class="(ifOpen ? 'openAfter' : 'openBefore') + ' flat-botton'"
          width="65"
          :src="require('../assets/apps-o.png')"
        />
      </template>
    </van-popover>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue';

export default defineComponent({
  props: {},
  setup(props, { emit }) {
    const showPopover = ref(false);
    const actions = [
      { text: '溯源查询', icon: 'search' },
      { text: '油机检定', icon: 'certificate' },
      {
        text: '投诉',
        icon: 'comment-o',
        image: require('../assets/complaint.png'),
      },
    ];

    const onSelect = (action, index) => {
      console.log(action, index);
      emit('select', { action, index });
    };

    let ifOpen = ref(false);
    const onOpen = () => {
      ifOpen.value = !ifOpen.value;
    };

    return {
      showPopover,
      actions,
      ifOpen,
      onSelect,
      onOpen,
    };
  },
});
</script>

<style scoped>
:root {
  /* rgba的四个值分别为：红(R)、绿(G)、蓝(B)、透明度(A) */
  --whiteShadow: 3px 3px 16px -4px rgba(0, 0, 0, 0.5);
}
.sticky {
  position: fixed;
  right: 16px;
  bottom: 70px;
  z-index: 999;
}
.flat-btn {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 3px 3px 16px -4px rgba(0, 0, 0, 0.5);
}

.openBefore {
  background: transparent;
}

.openAfter {
  background: #fff;
}
</style>

<style>
.flat-botton {
  z-index: 3000;
  /* background: #f0f8ff; */
  border-radius: 50%;
}
/* .van-overlay,
.van-popover {
  z-index: 1 !important;
} */
</style>
