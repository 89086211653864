<template>
  <div class="layout" id="comments">
    <van-form style="margin-top: 16px" @submit="onSubmit">
      <van-cell-group inset class="comment-cell-group">
        <van-field
          v-model="state.typeMsg"
          readonly
          clickable
          name="typeMsg"
          label="投诉类型"
          placeholder="点击选择投诉类型"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择投诉类型' }]"
        />
        <van-popup v-model:show="showPicker" position="bottom" teleport="body">
          <van-picker
            title="投诉类型"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
      </van-cell-group>
      <van-cell-group inset class="comment-cell-group" style="margin-top: 16px">
        <van-field
          v-model="state.comments"
          rows="3"
          autosize
          name="comments"
          type="textarea"
          maxlength="200"
          placeholder="请详细填写您遇到的问题，以便尽快为您核实处理"
          show-word-limit
          :rules="[{ required: true, message: '请先写您遇到的问题' }]"
        />
      </van-cell-group>
      <van-cell-group inset class="comment-cell-group" style="margin-top: 16px">
        <van-field
          v-model="state.nickname"
          name="nickname"
          label="投诉人"
          placeholder="投诉人（选填）"
          autocomplete="off"
          :rules="[
            { validator: validatorLength, message: '投诉人最长输入12个字' },
          ]"
        />
        <van-field
          v-model="state.mobile"
          name="mobile"
          label="联系电话"
          placeholder="联系电话（选填）"
          autocomplete="off"
          :rules="[
            { validator: validatorLength, message: '联系电话最长输入12个字' },
            { validator: validatorPhone, message: '手机号格式不正确' },
          ]"
        />
      </van-cell-group>

      <div style="margin: 16px">
        <van-button
          block
          type="primary"
          :disabled="btnDisabled"
          style="letter-spacing: 15px"
          native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Notify } from 'vant';
import { getComments } from '../api';

const route = useRoute();

const siteCode =
  localStorage.getItem('siteCode').replace(/^\s+|\s+$/g, '') ||
  route.query.site_code;
const tankerCode =
  localStorage.getItem('tankerCode').replace(/^\s+|\s+$/g, '') ||
  route.query.tanker_code;
const btnDisabled = ref(false);
const state = reactive({
  creditCode: siteCode,
  tankerCode,
  typeMsg: '',
  type: '',
  comments: '',
  nickname: '',
  mobile: '',
});
const initData = () => {
  setTimeout(() => {
    state.typeMsg = '';
    state.type = '';
    state.comments = '';
    state.nickname = '';
    state.mobile = '';
  }, 500);
};

const onSubmit = async (values) => {
  const res = await getComments({
    ...state,
    ...values,
  });
  console.log(res);
  if (res.status === 0) {
    Notify({ type: 'success', message: '投诉成功' });
    btnDisabled.value = true;
    initData();
  } else {
    Notify({ type: 'error', message: '投诉失败' });
  }
};

const showPicker = ref(false);

const onConfirm = (value) => {
  state.typeMsg = value;
  state.type = columns.indexOf(value) + 1;
  showPicker.value = false;
};
//  1：油品质量 2：计量问题 3：发票问题 4：其他 0：传入其他值
const columns = ['油品质量', '计量问题', '发票问题', '其他'];

const validatorLength = (val) => val.length <= 12;
const validatorPhone = (value) => {
  const reg = /^1(3|4|5|6|7|8|9)\d{9}$|^0\d{2,3}-\d{7,8}$/;
  if (value) {
    return reg.test(value);
  } else {
    return true;
  }
};
</script>

<style scoped>
.layout {
  position: relative;
  width: 100vw;
  min-height: calc(100vh - 120px);
}

.comment-cell-group {
  box-shadow: 0px 12px 18px 0px rgba(214, 214, 214, 0.2);
  border-radius: 0;
}
</style>
