<template>
  <div
    ref="dragVerifyRef"
    class="drag_verify"
    :style="dragVerifyStyle"
    @mousemove="dragMoving"
    @mouseup="dragFinish"
    @mouseleave="dragFinish"
    @touchmove="dragMoving"
    @touchend="dragFinish"
  >
    <div
      class="dv_progress_bar"
      :class="{ goFirst2: isOk }"
      ref="progressBarRef"
      :style="progressBarStyle"
    ></div>
    <div class="dv_text" :style="textStyle" ref="messageRef">
      <slot name="textBefore" v-if="slots.textBefore"></slot>
      {{ message }}
      <slot name="textAfter" v-if="slots.textAfter"></slot>
    </div>
    <div
      class="dv_handler dv_handler_bg"
      :class="{ goFirst: isOk }"
      @mousedown="dragStart"
      @touchstart="dragStart"
      ref="handlerRef"
      :style="handlerStyle"
    >
      <van-icon v-if="!isPassing" :name="handlerIcon" />
      <van-icon v-else :name="successIcon" :color="progressBarBg" />
    </div>
  </div>
</template>
<script setup>
import {
  onMounted,
  ref,
  computed,
  defineProps,
  defineEmits,
  useSlots,
} from 'vue';

const props = defineProps({
  width: {
    type: Number,
    default: 300,
  },
  height: {
    type: Number,
    default: 45,
  },
  text: {
    type: String,
    default: '向右滑动',
  },
  successText: {
    type: String,
    default: '验证成功',
  },
  background: {
    type: String,
    default: '#F0F1F5',
  },
  progressBarBg: {
    type: String,
    default: '#76c61d',
  },
  completedBg: {
    type: String,
    default: '#76c61d',
  },
  boxShadowColor: {
    type: String,
    default: '#e6eef5',
  },
  borderColor: {
    type: String,
    default: '#d5dee6',
  },
  circle: {
    type: Boolean,
    default: false,
  },
  radius: {
    type: String,
    default: '4px',
  },
  handlerIcon: {
    type: String,
  },
  successIcon: {
    type: String,
  },
  handlerBg: {
    type: String,
    default: '#fff',
  },
  textSize: {
    type: String,
    default: '14px',
  },
  textColor: {
    type: String,
    default: '#53565C',
  },
});

const dragVerifyRef = ref(null);
const handlerRef = ref(null);
const progressBarRef = ref(null);
const messageRef = ref(null);

const slots = useSlots();

const isPassing = ref(false);
const isMoving = ref(false);
const x = ref(0);
const isOk = ref(false);

const emit = defineEmits([
  'handlerMove',
  'passfail',
  'onSuccess',
  'passcallback',
]);

const handlerStyle = computed(() => {
  return {
    width: props.height - 2 + 'px',
    height: props.height - 2 + 'px',
    background: props.handlerBg,
    borderRadius: props.circle ? props.height / 2 + 'px' : props.radius,
    boxShadow: `2px 2px 4px 0 ${props.boxShadowColor}`,
    border: `1px solid ${props.borderColor}`,
  };
});
const message = computed(() => {
  console.log(isPassing.value);
  return isPassing.value ? props.successText : props.text;
});
const dragVerifyStyle = computed(() => {
  return {
    width: props.width + 'px',
    height: props.height + 'px',
    lineHeight: props.height + 'px',
    background: props.background,
    borderRadius: props.circle ? props.height / 2 + 'px' : props.radius,
  };
});
const progressBarStyle = computed(() => {
  return {
    background: props.progressBarBg,
    height: props.height + 'px',
    borderRadius: props.circle
      ? props.height / 2 + 'px 0 0 ' + props.height / 2 + 'px'
      : props.radius,
  };
});
const textStyle = computed(() => {
  return {
    height: props.height + 'px',
    width: props.width + 'px',
    fontSize: props.textSize,
  };
});

onMounted(() => {
  const dragEl = dragVerifyRef.value;
  dragEl.style.setProperty('--textColor', props.textColor);
  dragEl.style.setProperty('--width', Math.floor(props.width / 2) + 'px');
  dragEl.style.setProperty('--pwidth', -Math.floor(props.width / 2) + 'px');
  console.log(slots);
});

const dragStart = (e) => {
  if (!isPassing.value) {
    isMoving.value = true;
    x.value = e.pageX || e.touches[0].pageX;
  }
  emit('handlerMove');
};

const dragMoving = (e) => {
  if (isMoving.value && !isPassing.value) {
    let _x = (e.pageX || e.touches[0].pageX) - x.value;

    let handler = handlerRef.value;
    if (_x > 0 && _x <= props.width - props.height) {
      handler.style.left = _x + 'px';
      progressBarRef.value.style.width = _x + props.height / 2 + 'px';
    } else if (_x > props.width - props.height) {
      handler.style.left = props.width - props.height + 'px';
      progressBarRef.value.style.width = props.width - props.height / 2 + 'px';
      passVerify();
    }
  }
};

const dragFinish = (e) => {
  if (isMoving.value && !isPassing.value) {
    let _x = (e.pageX || e.changedTouches[0].pageX) - x.value;
    if (_x < props.width - props.height) {
      isOk.value = true;
      setTimeout(() => {
        handlerRef.value.style.left = '0';
        progressBarRef.value.style.width = '0';
        isOk.value = false;
      }, 500);
      emit('passfail');
    } else {
      let handler = handlerRef.value;
      handler.style.left = props.width - props.height + 'px';
      progressBarRef.value.style.width = props.width - props.height / 2 + 'px';
      passVerify();
    }
    isMoving.value = false;
  }
};

const passVerify = () => {
  isPassing.value = true;
  setTimeout(() => {
    emit('onSuccess', true);
  }, 1000);
  isMoving.value = false;
  let handler = handlerRef.value;
  handler.children[0].name = props.successIcon;
  progressBarRef.value.style.background = props.completedBg;
  messageRef.value.style['-webkit-text-fill-color'] = 'unset';
  messageRef.value.style.animation = 'slidetounlock2 3s infinite';
  messageRef.value.style.color = '#fff';
  emit('passcallback');
};
</script>
<style scoped>
.drag_verify {
  position: relative;
  background-color: #e8e8e8;
  text-align: center;
  overflow: hidden;
}
.drag_verify .dv_handler {
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: move;
}
.drag_verify .dv_handler i {
  color: #666;
  padding-left: 0;
  font-size: 16px;
}
.drag_verify .dv_handler .el-icon-circle-check {
  color: #6c6;
  margin-top: 9px;
}
.drag_verify .dv_progress_bar {
  position: absolute;
  height: 34px;
  width: 0px;
}
.drag_verify .dv_text {
  position: absolute;
  top: 0px;
  color: transparent;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, var(--textColor)),
    color-stop(0.4, var(--textColor)),
    color-stop(0.5, #fff),
    color-stop(0.6, var(--textColor)),
    color-stop(1, var(--textColor))
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-size-adjust: none;
  animation: slidetounlock 3s infinite;
}
.drag_verify .dv_text * {
  -webkit-text-fill-color: var(--textColor);
}
.goFirst {
  left: 0px !important;
  transition: left 0.5s;
}
.goFirst2 {
  width: 0px !important;
  transition: width 0.5s;
}
</style>
<style>
@-webkit-keyframes slidetounlock {
  0% {
    background-position: var(--pwidth) 0;
  }
  100% {
    background-position: var(--width) 0;
  }
}
@-webkit-keyframes slidetounlock2 {
  0% {
    background-position: var(--pwidth) 0;
  }
  100% {
    background-position: var(--pwidth) 0;
  }
}
</style>
