<template>
  <div class="layout">
    <div v-if="traceList.length === 0 || TimeOut" class="empty">
      <img src="@/assets/empty.png" />
      <div>本站当前油品溯源信息未知</div>
    </div>
    <van-swipe v-else ref="swipe" :loop="false" @change="handleChangeSwipe">
      <template v-for="(trace, index) in traceList" :key="index">
        <van-swipe-item class="van-swipe-trance-item">
          <div class="trace-item">
            <div
              class="trace-item__title"
              :style="{
                background: colorList[index % 3],
                '--color': colorList[index % 3],
              }"
            >
              <span>{{ trace.tankerName }}</span>
              <span>{{ trace.oilName }}</span>
              <span>{{ trace.tankNum }}号油罐</span>
            </div>
            <van-cell-group class="trace-item__cell" inset>
              <Timeline
                :color="colorList[index % 3]"
                :border-color="bColorList[index % 3]"
              >
                <TimelineItem label="来源炼厂" :value="trace.refineryName">
                  <ul>
                    <li class="info">
                      <span class="label">油品：</span>
                      {{ trace.oilName || '-' }}
                    </li>
                    <li class="info">
                      <span class="label">密度：</span>
                      {{ trace.density || '-' }} kg/m³
                    </li>
                    <li class="info">
                      <span class="label">进油量：</span>
                      {{ toFixed(trace.takingSum) || '-' }} 吨
                    </li>
                  </ul>
                </TimelineItem>
                <TimelineItem label="发货油库" :value="trace.oilDepot" />
                <TimelineItem
                  label="运输车辆"
                  :value="trace.licenseNum || '-'"
                />
                <TimelineItem label="加油站" :value="trace.siteName" />
                <TimelineItem
                  label="油罐编号"
                  :value="`${trace.tankNum}号油罐`"
                />
                <TimelineItem label="加油机" :value="trace.tankerName || '-'" />
                <TimelineItem label="加油枪" :value="trace.gunName || '-'" />
                <TimelineItem
                  label="卸油时间"
                  :value="trace.completeTime || '-'"
                />
                <TimelineItem
                  v-if="trace.time"
                  label="加油时间"
                  :value="trace.time"
                />
              </Timeline>
            </van-cell-group>

            <van-cell-group class="trace-item__cell top-item__cell" inset>
              <van-cell title="单据证明" is-link @click="showBottom = true" />
              <!-- <van-cell title="运输路线" is-link @click="mapBottom = true" /> -->
            </van-cell-group>
            <!-- 单据证明 -->
            <van-popup
              v-model:show="showBottom"
              position="bottom"
              teleport="body"
              :style="{ height: '80%' }"
            >
              <van-config-provider
                v-for="(img, index) of trace.imgList"
                :key="index"
                :theme-vars="cellGroupThemeVars"
              >
                <van-cell-group
                  inset
                  class="cell-group"
                  v-if="!!img"
                  :title="PIC_TITLES[index]"
                  style="
                  padding: 16px;
                  box-sizing: border-box;
                  text-align: center;
                "
                >
                  <Image
                    :src="img"
                    :item="trace.tankerCode"
                    height="8rem"
                    fit="contain"
                    :type="index"
                  >
                    <template #index>{{ PIC_TITLES[index] }}</template>
                  </Image>
                </van-cell-group>
              </van-config-provider>
            </van-popup>
            <!-- 运输路线 -->
            <!-- <van-popup
              v-model:show="mapBottom"
              position="bottom"
              teleport="body"
              :style="{ height: '80%' }"
            >
              <TransportMap />
            </van-popup> -->

            <!-- <template > -->
            <!-- </template> -->
          </div>
        </van-swipe-item>
      </template>
      <!-- <template #indicator v-if="false">
        <div class="custom-indicator" v-if="traceList.length > 1">
          <div
            class="custom-indicator-active"
            :style="{
              width: indicatorWidth + 'px',
              transform: `translateX(${current * indicatorWidth}px)`,
            }"
          ></div>
        </div>
      </template> -->
      <template #indicator>
        <div class="indicators">
          <!-- <van-image
            @click="handelIndicator('left')"
            width="80"
            class="arrow left"
            v-if="traceList.length > 1 && current != traceList.length - 1"
            :src="require('../assets/arrow.png')"
          />
          <van-image
            @click="handelIndicator('right')"
            width="80"
            class="arrow right"
            v-if="traceList.length > 1 && current != 0"
            :src="require('../assets/arrow.png')"
          /> -->

          <van-icon
            class="arrow left"
            @click="handelIndicator('left')"
            v-if="traceList.length > 1 && current != traceList.length - 1"
            name="arrow"
          />
          <van-icon
            class="arrow right"
            @click="handelIndicator('right')"
            v-if="traceList.length > 1 && current != 0"
            name="arrow-left"
          />
        </div>
      </template>
    </van-swipe>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { Notify } from 'vant';
import Timeline from '../components/Timeline.vue';
import TimelineItem from '../components/TimelineItem.vue';
import Image from '../components/Image.vue';
// import TransportMap from './TransportMap.vue';
import { getOilTrace } from '../api';
import { toFixed } from '../utils/utils';
import dayjs from 'dayjs';

const route = useRoute();

// 自定义 vant cell-group 组件的样式
const cellGroupThemeVars = {
  cellGroupTitleColor: 'var(--van-gray-7)',
  cellGroupInsetTitlePadding: '16px 8px 8px 24px',
  cellGroupTitleFontSize: 'var(--van-font-size-lg)',
};
// 当前指示器的下标值
const current = ref(0);
//
const colorList = ref(['#1D62ED', '#FF534A', '#FFBA43']);
const bColorList = ref(['#B0CAFF', '#FFBAB7', '#FFE3B4']);
// 接口请求的数据
const traceList = ref([]);
// 自定义指示器的宽度
const indicatorWidth = ref(0);
// 弹出层
const showBottom = ref(false);
// const mapBottom = ref(false);

// 页面图片标题模块名称映射
const PIC_TITLES = {
  outOrderImg: '出库单',
  carImg: '运输车辆',
  certImg: '油品合格证',
};
// 获取所需的参数值
const siteCode =
  localStorage.getItem('siteCode').replace(/^\s+|\s+$/g, '') ||
  route.query.siteCode;
const tankerCode =
  localStorage.getItem('tankerCode').replace(/^\s+|\s+$/g, '') ||
  route.query.tankerCode;

// 判断最后一次是否超时
const TimeOut = computed(() => {
  const time = dayjs(traceList.value[0].completeTime);
  const currentTime = dayjs();
  const difference = currentTime.diff(time);
  return difference > 90 * 24 * 60 * 60 * 1000;
});

const setCurrentIndicatorIndex = (index) => {
  current.value = index;
};
// 切换 Swipe 的 change 事件
const handleChangeSwipe = (index) => {
  setCurrentIndicatorIndex(index);
};

/**
 * 获取 Swipe 自定义指示器的宽度
 * @param {number} num 指示器的个数
 * @return {number} 自定义指示器的宽度
 */
const getIndicatorWidth = (num) => {
  const regex = /^\+?[1-9][0-9]*$/;
  if (!regex.test(num)) {
    // throw new Error('num 必须为正整数！')
    return 0;
  }
  const padding = 16 * 2; // 左右内边距
  return (document.body.clientWidth - padding) / num;
};

const transformResData = (data) => {
  let newTraceData = [];
  if (!(data instanceof Array)) {
    throw new Error("The type of 'data' is not array");
  }
  data.forEach((item) => {
    const { carImg, certImg, outOrderImg, ...otherData } = item;
    const obj = {
      ...otherData,
      imgList: {
        certImg,
        carImg,
        outOrderImg,
      },
    };
    newTraceData.push(obj);
  });

  indicatorWidth.value = getIndicatorWidth(newTraceData.length);

  return newTraceData;
};

const swipe = ref(null);
const handelIndicator = (type) => {
  if (type == 'left') {
    swipe.value.next();
  } else {
    swipe.value.prev();
  }
};

// 数组对象根据时间排序
const sortArrayDate = (arr, key) => {
  arr.sort((a, b) => {
    const dateA = new Date(a[key]);
    const dateB = new Date(b[key]);
    return dateB - dateA;
  });
  return arr;
};

onMounted(async () => {
  // localStorage.setItem('siteName', '');
  const paramsData = route.query;
  if (paramsData.data) {
    const data = JSON.parse(decodeURIComponent(paramsData.data));
    const res = await getOilTrace(data);
    if (res && res.status === 0) {
      // traceList.value = res.data;
      traceList.value = sortArrayDate(
        transformResData(res.data),
        'completeTime'
      );
    }
  } else {
    localStorage.setItem('oilList', '');
    const res = await getOilTrace({
      siteCode,
      tankerCode,
    });
    if (res.status === 0) {
      if (res.data && res.data.length > 0) {
        let oilList = [];
        res.data.forEach((item) => {
          if (!item.oilName) {
            return;
          }
          oilList.push(item.oilName);
        });
        oilList = [...new Set(oilList)];
        localStorage.setItem('oilList', oilList.toString());
      } else {
        Notify({
          message: '无相关数据',
          position: 'top',
        });
      }
      traceList.value = sortArrayDate(
        transformResData(res.data),
        'completeTime'
      );
    }
  }
});
</script>

<style lang="less" scoped>
.layout {
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
}
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: #333333;
  text-align: center;
  font-family: 'PingFang SC';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.empty img {
  width: 200px;
  height: 200px;
}

.trace-item {
  padding-top: 5px;
  margin-bottom: 16px;
}

.trace-item__title {
  margin: auto 16px;
  padding: 12px 16px;
  background: #1d62ed;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.info {
  display: flex;
  justify-content: space-between;
}
.label {
  color: gray;
}

.custom-indicator {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 0px;
  display: flex;
  height: 5px;
  font-size: 12px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
}

.custom-indicator-active {
  border-radius: 5px;
  background: #02b94b;
  transition: all 0.3s ease;
}

.trace-item__title::before,
.trace-item__title::after,
.trace-item__cell::before,
.trace-item__cell::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  z-index: 10;
  background: #f0f8ff;
  bottom: 0;
}

.trace-item__cell {
  position: relative;
  padding-top: 16px;
  box-shadow: 0px 12px 18px 0px rgba(214, 214, 214, 0.2);
  border-radius: 0;
}
.top-item__cell {
  margin-top: 16px;
}

.trace-item__title::before {
  left: 0;
  background-image: radial-gradient(
    circle 6px at 0 100%,
    transparent 50px,
    var(--color) 50%
  );
}
.trace-item__title::after {
  right: 0;
  background-image: radial-gradient(
    circle 6px at 100% 100%,
    transparent 50px,
    var(--color) 50%
  );
}

.trace-item__cell::before {
  top: 0;
  right: 0;
  background-image: radial-gradient(
    circle 6px at 100% 0%,
    transparent 50px,
    #fff 50%
  );
}
.trace-item__cell::after {
  top: 0;
  left: 0;
  background-image: radial-gradient(
    circle 6px at 0% 0%,
    transparent 50px,
    #fff 50%
  );
}

.trace-item__cell {
  position: relative;
  padding-top: 16px;
  box-shadow: 0px 12px 18px 0px rgba(214, 214, 214, 0.2);
  border-radius: 0;
}

.arrow {
  position: absolute;
  top: 250px;
  left: 0;
  color: #1d62ed;
  font-size: 40px;
  font-weight: bold;
}
.left {
  left: calc(100% - 50px);
}
</style>
<style lang="less">
.van-popover__action {
  background: #f0f4fa;
  border-radius: 22px;
  margin-bottom: 15px;
  line-height: 40px;
  height: 40px;
}

.van-popover--light .van-popover__content {
  background: transparent;
}

.van-popover__arrow {
  display: none;
}

.van-popover__action:last-child {
  margin-bottom: 0;
}

.cell-group {
  box-shadow: 0px 12px 18px 0px rgba(214, 214, 214, 0.2);
  border-radius: 0;
}
</style>
